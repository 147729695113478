<div class="main overflow-hidden">
    <app-scrollup></app-scrollup>
    <app-header-one></app-header-one>
    <app-breadcrumb-portfolio-no-gap></app-breadcrumb-portfolio-no-gap>
    <app-portfolio-five></app-portfolio-five>
    <app-review-one></app-review-one>
    <app-contact-one></app-contact-one>
    <app-cta></app-cta>
    <app-footer-one></app-footer-one>
    <app-modal-search></app-modal-search>
    <app-modal-menu></app-modal-menu>
</div>