<section class="section promo-area ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 res-margin">
                <!-- Single Promo -->
                <div class="single-promo grad-hover text-center p-5">
                    <!-- Promo Wrapper -->
                    <div class="promo-wrapper">
                        <h3 class="mb-3">Zero to Hero</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. <strong>Aspernatur provident unde</strong> ex eligendi magni sit impedit iusto, sed ad fuga minima, dignissimos ducimus autem molestias, nostrum nesciunt enim? Ea, non hic voluptates dolorum impedit eveniet dolorem temporibus illo incidunt quis minima facere doloribus sit maiores, blanditiis labore quasi, accusantium quaerat!</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 res-margin">
                <!-- Single Promo -->
                <div class="single-promo grad-hover active text-center p-5">
                    <!-- Promo Wrapper -->
                    <div class="promo-wrapper">
                        <h3 class="mb-3">Dedicated Support</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur provident unde ex eligendi magni sit impedit iusto, sed ad fuga minima, <strong>dignissimos ducimus autem</strong> molestias, nostrum nesciunt enim? Ea, non hic voluptates dolorum impedit eveniet dolorem temporibus illo incidunt quis minima facere doloribus sit maiores, blanditiis labore quasi, accusantium quaerat!</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Promo -->
                <div class="single-promo grad-hover text-center p-5">
                    <!-- Promo Wrapper -->
                    <div class="promo-wrapper">
                        <h3 class="mb-3">100% Up-time</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur provident unde ex eligendi magni sit impedit iusto, sed ad fuga minima, dignissimos ducimus autem molestias, nostrum nesciunt enim? Ea, non hic voluptates <strong>dolorum impedit eveniet dolorem temporibus</strong> illo incidunt quis minima facere doloribus sit maiores, blanditiis labore quasi, accusantium quaerat!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>