import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';



@Component({
  selector: 'app-modal-search',
  templateUrl: './modal-search.component.html',
  styleUrls: ['./modal-search.component.css']
})


export class ModalSearchComponent implements OnInit {
  compatibility:any;
  imei: string = '';

 imeiForm = new FormGroup({
   imei : new FormControl('')
 });

 
 alert=0;
 

  constructor(private http: HttpClient) { }

  ngOnInit(): void {

  }

  checkCompatibility(){
    this.alert =3;
    console.log(this.imeiForm.value);
    this.http.get<any>('https://bait.ordenaris.com/altan/consulta?imei='+this.imeiForm.value.imei).subscribe(data => {
      this.compatibility = data;
      console.log(this.compatibility);
      if (this.compatibility.deviceFeatures.band28 == "SI" && this.compatibility.imei.blocked == "NO" && this.compatibility.imei.homologated=="HOMOLOGADOS O VOLTE"){
        this.alert =1;
      } else{
        this.alert =2;
      }
  }, e =>{
    this.alert =2;
  })
  }
  close() {
    this.alert =0;
  }
}
