import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-blog-right-sidebar',
  templateUrl: './breadcrumb-blog-right-sidebar.component.html',
  styleUrls: ['./breadcrumb-blog-right-sidebar.component.css']
})
export class BreadcrumbBlogRightSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
