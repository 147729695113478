<section class="section content-area bg-grey ptb_100">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-lg-6">
                <!-- Content Inner -->
                <div class="content-inner text-center">
                    <!-- Section Heading -->
                    <div class="section-heading text-center mb-3">
                        <h2>We help to grow your business.</h2>
                        <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                        <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                    </div>
                    <!-- Content List -->
                    <ul class="content-list text-left">
                        <!-- Single Content List -->
                        <li class="single-content-list media py-2">
                            <div class="content-icon pr-4">
                                <span class="color-1"><i class="fas fa-angle-double-right"></i></span>
                            </div>
                            <div class="content-text media-body">
                                <span><b>Online Presence</b><br>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, distinctio.</span>
                            </div>
                        </li>
                        <!-- Single Content List -->
                        <li class="single-content-list media py-2">
                            <div class="content-icon pr-4">
                                <span class="color-1"><i class="fas fa-angle-double-right"></i></span>
                            </div>
                            <div class="content-text media-body">
                                <span><b>Marketing Stretagy</b><br>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, distinctio.</span>
                            </div>
                        </li>
                        <!-- Single Content List -->
                        <li class="single-content-list media py-2">
                            <div class="content-icon pr-4">
                                <span class="color-1"><i class="fas fa-angle-double-right"></i></span>
                            </div>
                            <div class="content-text media-body">
                                <span><b>Promote Local Sales</b><br>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, distinctio.</span>
                            </div>
                        </li>
                    </ul>
                    <a href="#" class="btn btn-bordered mt-4">Learn More</a>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <!-- Service Thumb -->
                <div class="service-thumb mx-auto pt-4 pt-lg-0">
                    <img src="assets/img/content_thumb.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>