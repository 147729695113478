<div class="main overflow-hidden">
    <app-scrollup></app-scrollup>
    <app-header-one></app-header-one>
    <app-breadcrumb-about></app-breadcrumb-about>
    <app-about-us></app-about-us>
    <app-goal></app-goal>
    <app-team-one></app-team-one>
    <app-contact-one></app-contact-one>
    <app-cta></app-cta>
    <app-footer-one></app-footer-one>
    <app-modal-search></app-modal-search>
    <app-modal-menu></app-modal-menu>
</div>