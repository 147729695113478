<div class="homepage-4">
    <div class="main overflow-hidden">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <app-hero-four></app-hero-four>
        <app-branding></app-branding>
        <app-content-one></app-content-one>
        <app-content-four></app-content-four>
        <app-service-four></app-service-four>
        <app-pricing-two></app-pricing-two>
        <app-review-one></app-review-one>
        <app-contact-one></app-contact-one>
        <app-cta></app-cta>
        <app-footer-one></app-footer-one>
        <app-modal-search></app-modal-search>
        <app-modal-menu></app-modal-menu>
    </div>
</div>