<section class="section our-goal ptb_100">
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-12 col-lg-6">
                <!-- Goal Content -->
                <div class="goal-content section-heading text-center text-lg-left pr-md-4 mb-0">
                    <h2 class="mb-3">Our Goal</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis tenetur maxime labore recusandae enim dolore, nesciunt, porro molestias ullam eum atque harum! Consectetur, facilis maxime ratione fugiat laborum omnis atque quae, molestiae rem perspiciatis veritatis cumque ex minima, numquam quis dicta impedit possimus tempore? Quo sequi labore, explicabo sit vitae.</p><br>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto iure excepturi eos, tenetur minima dignissimos repellendus laudantium, rem, quo ipsam esse maiores sequi ex debitis quae facilis dolorum voluptates animi.</p>
                    <a href="#" class="btn btn-bordered mt-4">Read More</a>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <!-- Goal Thumb -->
                <div class="goal-thumb mt-5 mt-lg-0">
                    <img src="assets/img/about_thumb_2.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>