<header id="header">
    <!-- Navbar -->
    <nav data-aos="zoom-out" data-aos-delay="800" class="navbar navbar-expand">
        <div class="container header">
            <!-- Navbar Brand-->
            <a class="navbar-brand" href="/">
                <img class="navbar-brand-regular" src="assets/img/logo2.png" alt="brand-logo">
                <img class="navbar-brand-sticky" src="assets/img/logo2.png" alt="sticky brand-logo">
            </a>
            <div class="ml-auto"></div>
            <!-- Navbar -->
            <ul class="navbar-nav items">
                <li class="nav-item dropdown">
                    <a class="nav-link" href="#">Inicio</a>
                    <!-- <ul class="dropdown-menu">
                        <li class="nav-item dropdown">
                            <a class="nav-link" href="#">Multi-Page <i class="fas fa-angle-right ml-1"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="/" class="nav-link">Inicio</a></li>
                                <li class="nav-item"><a href="/theme-two" class="nav-link">Homepage 2</a></li>
                                <li class="nav-item"><a href="/theme-three" class="nav-link">Homepage 3</a></li>
                                <li class="nav-item"><a href="/theme-four" class="nav-link">Homepage 4</a></li>
                                <li class="nav-item"><a href="/theme-five" class="nav-link">Homepage 5</a></li>
                                <li class="nav-item"><a href="/theme-six" class="nav-link">Homepage 6</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link" href="#">One-Page <i class="fas fa-angle-right ml-1"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="/theme-seven" class="nav-link">Homepage 1</a></li>
                                <li class="nav-item"><a href="/theme-eight" class="nav-link">Homepage 2</a></li>
                                <li class="nav-item"><a href="/theme-nine" class="nav-link">Homepage 3</a></li>
                                <li class="nav-item"><a href="/theme-ten" class="nav-link">Homepage 4</a></li>
                                <li class="nav-item"><a href="/theme-eleven" class="nav-link">Homepage 5</a></li>
                                <li class="nav-item"><a href="/theme-twelve" class="nav-link">Homepage 6</a></li>
                            </ul>
                        </li>
                    </ul> -->
                </li>
                <li class="nav-item">
                    <a href="/planes" class="nav-link">Precios</a>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link" href="https://recarga.mimovil.com.mx/mi-movil" target="blank">Recargar</a>
                </li>
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link" href="#">Blog Pages <i class="fas fa-angle-down ml-1"></i></a>
                    <ul class="dropdown-menu">
                        <li class="nav-item dropdown">
                            <a class="nav-link" href="#">Blog Grid <i class="fas fa-angle-right ml-1"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="/blog-two-column" class="nav-link">Grid 2 Column</a></li>
                                <li class="nav-item"><a href="/blog-three-column" class="nav-link">Grid 3 Column</a></li>
                                <li class="nav-item"><a href="/blog-left-sidebar" class="nav-link">Left Sidebar</a></li>
                                <li class="nav-item"><a href="/blog-right-sidebar" class="nav-link">Right Sidebar</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link" href="#">Blog Single <i class="fas fa-angle-right ml-1"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="/blog-details-left-sidebar" class="nav-link">Single Left Sidebar</a></li>
                                <li class="nav-item"><a href="/blog-details-right-sidebar" class="nav-link">Single Right Sidebar</a></li>
                            </ul>
                        </li>
                    </ul>
                </li> -->
                <li class="nav-item dropdown">
                    <a class="nav-link" href="#" data-toggle="modal" data-target="#search">Compatibilidad</a>
                </li>
            </ul>
            <!-- Navbar Icons -->
            <ul class="navbar-nav icons">
                <li class="nav-item social">
                    <a href="https://www.facebook.com/mimovildistribuidor" target="blank" class="nav-link"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li class="nav-item social">
                    <a href="https://www.instagram.com/mimovildistribuidor" target="blank" class="nav-link"><i class="fab fa-instagram"></i></a>
                </li>
            </ul>

            <!-- Navbar Toggler -->
            <ul class="navbar-nav toggle">
                <li class="nav-item">
                    <a href="#" class="nav-link" data-toggle="modal" data-target="#menu">
                        <i class="fas fa-bars toggle-icon m-0"></i>
                    </a>
                </li>
            </ul>

            <!-- Navbar Action Button -->
            <ul class="navbar-nav action">
                <li class="nav-item ml-3">
                    <a href="https://api.whatsapp.com/send/?phone=5212285160419&text=%C2%A1Hola%21.+Me+gustar%C3%ADa%20aprovechar%20la%20promici%C3%B3n%20del%20mes" class="btn ml-lg-auto btn-bordered-green"><i class="fas fa-paper-plane contact-icon fab fa-whatsapp mr-md-2"></i>Whatsapp</a>
                </li>
            </ul>
        </div>
    </nav>
</header>