<div class="main overflow-hidden">
    <app-scrollup></app-scrollup>
    <app-header-one></app-header-one>
    <app-hero-one></app-hero-one>
    <app-promo-one></app-promo-one>
    <app-content-one></app-content-one>
    <app-content-four></app-content-four>
    <app-service-one></app-service-one>
    <app-portfolio-one></app-portfolio-one>
    <app-pricing-one></app-pricing-one>
    <app-review-one></app-review-one>
    <app-contact-one></app-contact-one>
    <app-cta></app-cta>
    <app-footer-one></app-footer-one>
    <app-modal-search></app-modal-search>
    <app-modal-menu></app-modal-menu>
</div>