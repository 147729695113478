<div class="homepage-2">
    <div class="main overflow-hidden">
        <app-scrollup></app-scrollup>
        <app-header-four></app-header-four>
        <app-hero-two></app-hero-two>
        <app-service-two></app-service-two>
        <app-video></app-video>
        <app-portfolio-two></app-portfolio-two>
        <app-content-one></app-content-one>
        <app-pricing-two></app-pricing-two>
        <app-review-one></app-review-one>
        <app-contact-one></app-contact-one>
        <app-cta></app-cta>
        <app-footer-one></app-footer-one>
        <app-modal-search></app-modal-search>
        <app-modal-menu></app-modal-menu>
    </div>
</div>