<section class="section about-area ptb_100">
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-12 col-lg-6">
                <!-- About Thumb -->
                <div class="about-thumb text-center">
                    <img src="assets/img/about_thumb_1.jpg" alt="">
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <!-- About Content -->
                <div class="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                    <h2 class="mb-3">We’re Your Partner in Your Success</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis tenetur maxime labore recusandae enim dolore, nesciunt, porro molestias ullam eum atque harum! Consectetur, facilis maxime ratione fugiat laborum omnis atque quae, molestiae rem perspiciatis veritatis cumque ex minima, numquam quis dicta impedit possimus tempore? Quo sequi labore, explicabo sit vitae.</p><br>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto iure excepturi eos, tenetur minima dignissimos repellendus laudantium, rem, quo ipsam esse maiores sequi ex debitis quae facilis dolorum voluptates animi.</p>
                    <!-- Counter Area -->
                    <div class="couter-area mt-5">
                        <!-- Single Counter -->
                        <div class="single-counter d-block d-md-inline-block m-4 m-md-2">
                            <div class="counter-inner">
                                <!-- Counter Item -->
                                <div class="counter-item d-inline-block mb-3">
                                    <span class="counter color-1 fw-7">375</span><span class="color-1 fw-7">+</span>
                                </div>
                            </div>
                            <h4 class="text-uppercase fw-7">Happy Clients</h4>
                        </div>
                        <!-- Single Counter -->
                        <div class="single-counter d-block d-md-inline-block m-4 m-md-2">
                            <div class="counter-inner">
                                <!-- Counter Item -->
                                <div class="counter-item d-inline-block mb-3">
                                    <span class="counter color-2 fw-7">1500</span><span class="color-2 fw-7">+</span>
                                </div>
                            </div>
                            <h4 class="text-uppercase fw-7">Projects Completed</h4>
                        </div>
                        <!-- Single Counter -->
                        <div class="single-counter d-block d-md-inline-block m-4 m-md-2">
                            <div class="counter-inner">
                                <!-- Counter Item -->
                                <div class="counter-item d-inline-block mb-3">
                                    <span class="counter color-3 fw-7">695</span><span class="color-3 fw-7">+</span>
                                </div>
                            </div>
                            <h4 class="text-uppercase fw-7">Running Projects</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>