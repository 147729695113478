<footer class="section footer-area dark-bg">
    <!-- Footer Top -->
    <div class="footer-top ptb_100">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white text-uppercase mb-2">MENÚ</h3>
                        <ul>
                            <li class="py-2"><a class="text-white-50" target="_blank" href="https://www.altanredes.com/Cobertura_Actual/mapa.html">Cobertura</a></li>
                            <li class="py-2"><a class="text-white-50" target="_blank" href="https://recarga.mimovil.com.mx/mi-movil">Recarga en Linea</a></li>
                            <li class="py-2"><a class="text-white-50" href="" data-toggle="modal" data-target="#search">Revisa la compatibilidad</a></li>
                            <li class="py-2"><a class="text-white-50" href="#">Atención a clientes MiMovil-Distribuidor: (228)516.04.19</a></li>
                            <li class="py-2"><a class="text-white-50" href="#">Atención a clientes MiMovil: (554)440.27.00</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white text-uppercase mb-2">PRIVACIDAD</h3>
                        <ul>
                            <li class="py-2"><a class="text-white-50" routerLink="/terminos_y_condiciones">Terminos y condiciones</a></li>
                            <li class="py-2"><a class="text-white-50" routerLink="/politicas_de_privacidad">Politicas de privacidad</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white text-uppercase mb-2">DESCARGA LA APP</h3>
                        <ul>
                            <li class="py-2"><a class="text-white-50" href="https://apps.apple.com/us/app/mi-m%C3%B3vil-la-red-sin-l%C3%ADmites/id1528558204"><img src="https://newww.mx/wp-content/uploads/2019/11/Download_on_the_App_Store_Badge_ESMX_RGB_blk_100217_black.png" alt=""></a></li>
                            <li class="py-2"><a class="text-white-50" href="https://play.google.com/store/apps/details?id=com.koonolmexico.client.pcuv2&hl=es_MX&gl=US"><img src="https://newww.mx/wp-content/uploads/2019/11/google-play-badge_color.png" alt=""></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white text-uppercase mb-2">Síguenos</h3>
                        <!-- Social Icons -->
                        <ul class="social-icons list-inline pt-2">
                            <li class="list-inline-item px-1"><a class="text-white-50" href="#"><i class="fab fa-facebook"></i></a></li>
                            <li class="list-inline-item px-1"><a class="text-white-50" href="#"><i class="fab fa-instagram"></i></a></li>
                            <li class="list-inline-item px-1"><a class="text-white-50" href="https://api.whatsapp.com/send/?phone=5212285160419&text=%C2%A1Hola%21.+Me+gustar%C3%ADa%20aprovechar%20la%20promoci%C3%B3n%20del%20mes"><i class="fab fa-whatsapp"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom dark-bg">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="copyright-left text-white-50">&copy; Copyrights 2022 All rights reserved.</div>
                        <!-- Copyright Right -->
                        <div class="copyright-right text-white-50">Made with <i class="fas fa-heart color-2"></i> By <a class="text-white-50" href="">4Network</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>