<div class="main overflow-hidden">
    <app-scrollup></app-scrollup>
    <app-header-one></app-header-one>
    <app-breadcrumb-pricing></app-breadcrumb-pricing>
    <app-pricing-four></app-pricing-four>
    <app-review-one></app-review-one>
    <app-contact-one></app-contact-one>
    <app-cta></app-cta>
    <app-footer-one></app-footer-one>
    <app-modal-search></app-modal-search>
    <app-modal-menu></app-modal-menu>
</div>