<section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-6">
                <div class="welcome-intro">
                    <h1 class="text-center text-md-left">INTERNET, LLAMADAS Y MENSAJES <br> <span class="fw-4 text-white">¡ILIMITADOS!</span></h1>
                    <p class="text-white text-center text-md-left my-4">Tecnología 4.5G
                        Conserva tu número actual, sin plazos forzosos, cancela cuando quieras, domicilia tu pago automáticamente, cobertura nacional e internacional, multi-operador siempre la mejor señal.
                        <br>
                        México 🇲🇽  USA 🇺🇸  Canadá 🇨🇦
                    </p>
                    <!-- Buttons -->
                    <div class="button-group d-flex align-items-center justify-content-center justify-content-md-start">
                        <a href="https://api.whatsapp.com/send/?phone=5212285160419&text=%C2%A1Hola%21.+Me+gustar%C3%ADa%20aprovechar%20la%20promoci%C3%B3n%20del%20mes" class="btn btn-bordered-orange">¡CAMBIARME YA!</a>
                        <!-- Play Button -->
                        <a class="play-btn" data-fancybox data-width="640" data-height="360" data-small-btn="true" href="https://youtu.be/GqO4p_VWz7Q">
                            <div class="btn-circle play-animation"></div>
                            <div class="btn-circle play-animation animation-short"></div>
                            <!-- Play Icon -->
                            <div class="play-icon">
                                <i class="fas fa-play"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb-wrapper pt-3 pt-md-5 mt-5">
                    <span class="welcome-thumb-1">
                        <img class="welcome-animation d-block ml-auto" src="assets/img/welcome_thumb_7.png" alt="">
                    </span> 
                    <span class="welcome-thumb-4">
                        <img class="welcome-animation d-block" src="assets/img/welcome_thumb_10.png" alt="">
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- Shape Bottom -->
    <div class="welcome-shape">
        <img src="assets/img/hero_shape.png" alt="">
    </div>
</section>