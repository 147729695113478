<div class="main overflow-hidden">
    <app-scrollup></app-scrollup>
    <app-header-one></app-header-one>
    <app-breadcrumb-team></app-breadcrumb-team>
    <app-team-two></app-team-two>
    <app-contact-three></app-contact-three>
    <app-cta></app-cta>
    <app-footer-one></app-footer-one>
    <app-modal-search></app-modal-search>
    <app-modal-menu></app-modal-menu>
</div>