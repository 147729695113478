<div class="page-width page-width--narrow section-template--15372864651450__main-padding">
    <h1 class="main-page-title page-title h0">
      Aviso de Privacidad
    </h1>
    <div class="rte">
      <p><span style="font-weight: 400;">“MiMóvil Distribuidor ”&nbsp;</span></p>
  <p><span style="font-weight: 400;">Este documento describe la forma en que recabamos y usamos los datos personales que usted nos brinda. También describe que tipo de datos son y qué opciones tiene para limitar el uso de dichos datos, así como el procedimiento para ejercer sus derechos ARCO.</span></p>
  <p><span style="font-weight: 400;">1) Responsable de la protección de sus datos personales.</span></p>
  <p><span style="font-weight: 400;">Arce Muñoz Ocampo (MiMóvil Distribuidor ), con domicilio en Priv. Adalberto Tejeda 2-25, Pumar, 91040 Xalapa-Enríquez, Veracruz, es responsable del tratamiento (uso) de sus datos personales.</span></p>
  <p><span style="font-weight: 400;">2) Nuestro Departamento de Protección de Datos</span></p>
  <p><span style="font-weight: 400;">Correo electrónico: hola@mimovil-distribuidor.com</span></p>
  <p><span style="font-weight: 400;">3) ¿Para qué fines recabamos y utilizamos sus datos personales?</span></p>
  <p><span style="font-weight: 400;">Sus datos personales serán utilizados para las siguientes finalidades:</span></p>
  <p><span style="font-weight: 400;">De los usuarios de los servicios de MiMóvil Distribuidor : Para efectos de poder enviarle los productos que desee comprar a la ubicación y dirección en donde usted elija. El teléfono por si se necesita contactarlo, el e mail para enviarle su confirmación del pedido y darle seguimiento a este. La tarjeta de crédito no la conocemos nosotros, sino un tercero para efectos de cobro. Sin embargo los datos se guardan en la bóveda de un tercero para solicitar los cargos de los servicios que solicite el usuario.</span></p>
  <p><span style="font-weight: 400;">4) ¿Qué datos personales obtenemos y de dónde?</span></p>
  <p><span style="font-weight: 400;">Para las finalidades señaladas anteriormente podemos recabar sus datos personales de distintas formas:</span></p>
  <p><span style="font-weight: 400;">Principalmente cuando visita LA PAGINA WEB: www.mimovil-distribuidor.com&nbsp;</span><span style="font-weight: 400;"><br></span><span style="font-weight: 400;">Mediante la formulación de encuestas físicas personales.&nbsp;</span><span style="font-weight: 400;"><br></span><span style="font-weight: 400;">Registro personal de cada cliente en nuestras oficinas por medio del formato general.&nbsp;</span><span style="font-weight: 400;"><br></span><span style="font-weight: 400;">Cuando visita nuestro sitio de Internet o utiliza nuestros servicios en línea.&nbsp;</span><span style="font-weight: 400;"><br></span><span style="font-weight: 400;">Por teléfono o correo electrónico.&nbsp;</span><span style="font-weight: 400;"><br></span><span style="font-weight: 400;">Por medio de las encuestas de satisfacción que de manera personal te realiza nuestro agente de ventas.&nbsp;</span><span style="font-weight: 400;"><br></span><span style="font-weight: 400;">Cuando obtenemos información a través de otras fuentes de acceso público que están permitidas por la ley (Directorios telefónicos o laborales, sitios web, etc).&nbsp;</span><span style="font-weight: 400;"><br><br></span></p>
  <p><span style="font-weight: 400;">Datos personales que recabamos:</span></p>
  <p><span style="font-weight: 400;">De los usuarios de MiMóvil Distribuidor - Nombre completo, domicilio, número de teléfono fijo, número de teléfono celular, IMEI, correo electrónico, cuentas de redes sociales.&nbsp;</span><span style="font-weight: 400;"><br></span><span style="font-weight: 400;"><br></span><span style="font-weight: 400;">Usted se compromete a que los datos proporcionados sean verídicos y reales, de lo contrario, cualquier dato falso o incorrecto será su responsabilidad.</span></p>
  <p><span style="font-weight: 400;">5) ¿Cómo Acceder, Rectificar, Cancelar u Oponerse al uso y tratamiento de sus datos personales (Derechos ARCO) o revocar su consentimiento para el tratamiento de sus datos?</span></p>
  <p><span style="font-weight: 400;">Usted tiene derecho constitucional de Acceder y conocer los datos personales que poseemos y a los detalles del tratamiento y uso de los mismos, así como a Rectificarlos y corregirlos en caso de ser inexactos o incompletos; Cancelarlos y eliminarlos cuando considere que no se requieren para alguna de las finalidades señalados en el presente aviso de privacidad, o que estén siendo utilizados para finalidades no autorizadas por usted o haya finalizado la relación contractual o de servicio, o bien, Oponerse al tratamiento de los mismos para fines específicos.</span></p>
  <p><span style="font-weight: 400;">Los mecanismos implementados para el ejercicio de sus Derechos ARCO, así como los plazos, información y documentación que debe contener su solicitud deben solicitarse al correo:</span></p>
  <p><span style="font-weight: 400;">hola@mimovil-distribuidor.com</span></p>
  <p><span style="font-weight: 400;">¿Cómo revocar el consentimiento para el tratamiento de sus datos personales?</span></p>
  <p><span style="font-weight: 400;">Si usted desea revocar el consentimiento que nos otorgó para el uso de sus datos personales, envíe su solicitud a nuestro Departamento de Protección de Datos Personales en donde le informaremos de los mecanismos implementados para ello al correo antes mencionado.</span></p>
  <p><span style="font-weight: 400;">6) Transferencia de datos personales</span></p>
  <p><span style="font-weight: 400;">Nosotros podemos transferir sus datos personales para cumplir con el servicio que requiere, por lo que sus datos personales podrán ser transferidos a terceras personas o empresas para el proceso de cobro que se hace a los usuarios del servicio, por lo anterior usted da su consentimiento para transferir dichos datos, en caso contrario enviar su negativa y oposición al correo </span><span style="font-weight: 400;">hola@mimovil-distribuidor.com</span></p>
  <p><span style="font-weight: 400;">Es importante señalar que nuestro sitio web puede tener enlaces a sitios web de terceros que, en caso de acceder, ocasionará que abandone nuestro sitio web, por lo cual no asumimos ninguna responsabilidad en relación a los sitios web de terceros.</span></p>
  <p><span style="font-weight: 400;">7) Modificaciones al aviso de privacidad</span></p>
  <p><span style="font-weight: 400;">Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso de Privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos. Estas modificaciones estarán disponibles al público en la dirección web:</span></p>
  <p><span style="font-weight: 400;">mimovil-distribuidor.com</span></p>
  <p><span style="font-weight: 400;">Uso de cookies, web beacons y JavaScript</span></p>
  <p><span style="font-weight: 400;">Las cookies son una pequeña pieza de información enviada automáticamente por nuestro sitio web, las cuales son almacenadas en su navegador. De esta manera el sitio web puede consultar dicha información para conocer la actividad previa del usuario y agilizar algunos procedimientos como mantener la sesión o identificar que secciones del sitio ya visitó.</span></p>
  <p><span style="font-weight: 400;">Las web beacons en conjunto con los JavaScripts de métricas web permiten almacenar información sobre los patrones de uso de nuestro sitio web. Le informamos que utilizamos esas herramientas para obtener información estadística como la siguiente:</span></p>
  <p><span style="font-weight: 400;">Tipo de navegador y sistema operativo, las páginas de Internet consultadas, origen de la visita, la dirección IP de acceso, tiempo de permanencia en nuestro sitio, entre otros datos estadísticos.</span></p>
  <p><span style="font-weight: 400;">Las cookies, el JavaScript y otras tecnologías de seguimiento y rastreo pueden ser deshabilitadas, sin embargo, desactivarlas puede generar que el sitio web o algunas de sus funcionalidades no se desempeñen adecuadamente.</span></p>
  <p><span style="font-weight: 400;">8) ¿Ante quién puede presentar sus quejas y denuncias por el tratamiento indebido de sus datos personales?</span></p>
  <p><span style="font-weight: 400;">Si después de haber ejercido sus Derechos ARCO ante MiMóvil Distribuidor  por medio del correo electrónico hola@mimovil-distribuidor.com dirigido a Arce Muñoz Ocampo mediante los mecanismos establecidos en este Aviso de Privacidad, usted considera que la respuesta ha sido insatisfactoria o incompleta; o presume que su derecho de protección de datos personales ha sido lesionado por alguna conducta o acción de nuestra parte; o cuenta con evidencia de que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá interponer la queja o denuncia correspondiente ante el IFAI.</span></p>
  <p><span style="font-weight: 400;">Febrero 2022.</span></p>
    </div>
  </div>