<section id="service" class="section service-area bg-grey ptb_150">
    <!-- Shape Top -->
    <div class="shape shape-top">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
            <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
        </svg>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>We provide the best digital services</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Service -->
                <div class="single-service p-4">
                    <span class="flaticon-rocket-launch color-1 icon-bg-1"></span>
                    <h3 class="my-3">Data Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt emit.</p>
                    <a class="service-btn mt-3" href="#">Learn More</a>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Service -->
                <div class="single-service p-4">
                    <span class="flaticon-monitoring color-2 icon-bg-2"></span>
                    <h3 class="my-3">Website Growth</h3>
                    <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt emit.</p>
                    <a class="service-btn mt-3" href="#">Learn More</a>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Service -->
                <div class="single-service p-4">
                    <span class="flaticon-web color-3 icon-bg-3"></span>
                    <h3 class="my-3">Seo Ranking</h3>
                    <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt emit.</p>
                    <a class="service-btn mt-3" href="#">Learn More</a>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Service -->
                <div class="single-service p-4">
                    <span class="flaticon-smartphone color-4 icon-bg-4"></span>
                    <h3 class="my-3">App Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt emit.</p>
                    <a class="service-btn mt-3" href="#">Learn More</a>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Service -->
                <div class="single-service p-4">
                    <span class="flaticon-email color-5 icon-bg-5"></span>
                    <h3 class="my-3">Email Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt emit.</p>
                    <a class="service-btn mt-3" href="#">Learn More</a>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Service -->
                <div class="single-service p-4">
                    <span class="flaticon-affiliate color-6 icon-bg-6"></span>
                    <h3 class="my-3">Affiliate Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt emit.</p>
                    <a class="service-btn mt-3" href="#">Learn More</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Shape Bottom -->
    <div class="shape shape-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
            <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
        </svg>
    </div>
</section>