<div class="blog">
    <div class="main overflow-hidden">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <app-breadcrumb-blog-left-sidebar></app-breadcrumb-blog-left-sidebar>
        <app-blog-three></app-blog-three>
        <app-footer-three></app-footer-three>
        <app-modal-search></app-modal-search>
        <app-modal-menu></app-modal-menu>
    </div>
</div>