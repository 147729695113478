import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.css']
})
export class HeaderOneComponent implements OnInit {

  compatibility:any;

  constructor() { }

  ngOnInit(): void {
  }





}
