<section class="section team-area bg-grey ptb_100">
    <!-- Shape Top -->
    <div class="shape shape-top">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
            <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
        </svg>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Our Experts</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Team -->
                <div class="single-team">
                    <!-- Team Photo -->
                    <div class="team-photo">
                        <a href="#"><img src="assets/img/team_thumb_1.jpg" alt=""></a>
                    </div>
                    <!-- Team Content -->
                    <div class="team-content p-3">
                        <a href="#">
                            <h3 class="mb-2">Junaid Hasan</h3>
                        </a>
                        <h5 class="text-uppercase">Marketing Officer</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Team -->
                <div class="single-team">
                    <!-- Team Photo -->
                    <div class="team-photo">
                        <a href="#"><img src="assets/img/team_thumb_2.jpg" alt=""></a>
                    </div>
                    <!-- Team Content -->
                    <div class="team-content p-3">
                        <a href="#">
                            <h3 class="mb-2">Yasmin Akter</h3>
                        </a>
                        <h5 class="text-uppercase">SEO Expert</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Team -->
                <div class="single-team">
                    <!-- Team Photo -->
                    <div class="team-photo">
                        <a href="#"><img src="assets/img/team_thumb_3.jpg" alt=""></a>
                    </div>
                    <!-- Team Content -->
                    <div class="team-content p-3">
                        <a href="#">
                            <h3 class="mb-2">Md. Arham</h3>
                        </a>
                        <h5 class="text-uppercase">Media Controller</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Team -->
                <div class="single-team">
                    <!-- Team Photo -->
                    <div class="team-photo">
                        <a href="#"><img src="assets/img/team_thumb_4.jpg" alt=""></a>
                    </div>
                    <!-- Team Content -->
                    <div class="team-content p-3">
                        <a href="#">
                            <h3 class="mb-2">Jassica William</h3>
                        </a>
                        <h5 class="text-uppercase">Data Analyst</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Team -->
                <div class="single-team">
                    <!-- Team Photo -->
                    <div class="team-photo">
                        <a href="#"><img src="assets/img/team_thumb_5.jpg" alt=""></a>
                    </div>
                    <!-- Team Content -->
                    <div class="team-content p-3">
                        <a href="#">
                            <h3 class="mb-2">John Doe</h3>
                        </a>
                        <h5 class="text-uppercase">Web Developer</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Team -->
                <div class="single-team">
                    <!-- Team Photo -->
                    <div class="team-photo">
                        <a href="#"><img src="assets/img/team_thumb_6.jpg" alt=""></a>
                    </div>
                    <!-- Team Content -->
                    <div class="team-content p-3">
                        <a href="#">
                            <h3 class="mb-2">Sara Scotch</h3>
                        </a>
                        <h5 class="text-uppercase">Graphic Designer</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Shape Bottom -->
    <div class="shape shape-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
            <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
        </svg>
    </div>
</section>