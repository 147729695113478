<section id="pricing" name="pricing" class="section price-plan-area bg-grey overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>PLANES DE PORTABILIDAD</h2>
                    <p class="d-none d-sm-block mt-4">Cambiate a MiMovil pagando sólo la mitad!.</p>
                    <p class="d-block d-sm-none mt-4">Internet, llamadas y SMS. todo ilimitado por 30 días.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-8">
                <div class="row price-plan-wrapper">
                    <div class="col-12 col-md-6 ">
                        <!-- Single Price Plan -->
                        <div class="single-price-plan color-2 bg-hover hover-top text-center p-5">
                            <!-- Plan Title -->
                            <div class="plan-title mb-2 mb-sm-3">
                                <h3 class="mb-2">Plan 200 <sup><span class="badge badge-pill badge-warning ml-2">promoción 50%</span></sup></h3>
                            </div>
                            <!-- Plan Price -->
                            <div class="plan-price pb-2 pb-sm-3">
                                <span class="color-primary fw-7">$</span>
                                <span class="h1 fw-7">100</span>
                                <sub class="validity text-muted fw-5">/ 30 Días</sub>
                            </div>
                            <!-- Plan Description -->
                            <div class="plan-description">
                                <ul class="plan-features">
                                    <li class="py-2">Internet Ilimitado</li>
                                    <li class="py-2">Llamadas Ilimitadas</li>
                                    <li class="py-2">SMS Ilimitados</li>
                                    <li class="py-2">México, USA y Canadá</li>
                                </ul>
                            </div>
                            <!-- Plan Button -->
                            <div class="plan-button">
                                <a href="https://api.whatsapp.com/send/?phone=5212285160419&text=%C2%A1Hola%21.+Me+gustar%C3%ADa%20aprovechar%20la%20promoci%C3%B3n%20del%20mes" class="btn btn-bordered mt-3">CONTRATAR</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mt-4 mt-md-0">
                        <!-- Single Price Plan -->
                        <div class="single-price-plan color-2 bg-hover active hover-top text-center p-5">
                            <!-- Plan Title -->
                            <div class="plan-title mb-2 mb-sm-3">
                                <h3 class="mb-2">Plan 300 <sup><span class="badge badge-pill badge-warning ml-2">promoción 50%</span></sup></h3>
                                <p></p>
                            </div>
                            <!-- Plan Price -->
                            <div class="plan-price pb-2 pb-sm-3">
                                <span class="color-primary fw-7">$</span>
                                <span class="h1 fw-7">150</span>
                                <sub class="validity text-muted fw-5">/ 30 Días</sub>
                            </div>
                            <!-- Plan Description -->
                            <div class="plan-description">
                                <ul class="plan-features">
                                    <li class="py-2">Internet Ilimitado</li>
                                    <li class="py-2">Llamadas Ilimitadas</li>
                                    <li class="py-2">SMS Ilimitados</li>
                                    <li class="py-2">México, USA y Canadá</li>
                                    <li class="py-2">HOT SPOT</li>
                                </ul>
                            </div>
                            <!-- Plan Button -->
                            <div class="plan-button">
                                <a href="https://api.whatsapp.com/send/?phone=5212285160419&text=%C2%A1Hola%21.+Me+gustar%C3%ADa%20aprovechar%20la%20promoci%C3%B3n%20del%20mes" class="btn btn-bordered mt-3">CONTRATAR</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center pt-5">
            <p class="pt-4 fw-5">¿No estas seguro de cual elegir? <a class="service-btn" href="https://api.whatsapp.com/send/?phone=5212285160419&text=%C2%A1Hola%21.+Me+gustar%C3%ADa%20aprovechar%20la%20promoci%C3%B3n%20del%20mes"><strong>Contactanos</strong></a></p>
        </div>
    </div>
</section>