<div class="blog blog-right">
    <div class="main overflow-hidden">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <app-breadcrumb-blog-right-sidebar></app-breadcrumb-blog-right-sidebar>
        <app-blog-four></app-blog-four>
        <app-footer-three></app-footer-three>
        <app-modal-search></app-modal-search>
        <app-modal-menu></app-modal-menu>
    </div>
</div>