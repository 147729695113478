<div class="homepage-5 dark">
    <div class="main overflow-hidden">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <app-hero-five></app-hero-five>
        <app-service-six></app-service-six>
        <app-content-three></app-content-three>
        <app-content-five></app-content-five>
        <app-pricing-three></app-pricing-three>
        <app-review-three></app-review-three>
        <app-contact-two></app-contact-two>
        <app-cta></app-cta>
        <app-footer-two></app-footer-two>
        <app-modal-search></app-modal-search>
        <app-modal-menu></app-modal-menu>
    </div>
</div>