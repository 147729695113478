<section class="section brand-partner ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7">
                <!-- Section Heading -->
                <div class="section-heading text-center mb-2">
                    <h2>We are working with</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12">
                <!-- Brand Partner Wrapper -->
                <ul class="brand-partner-wrapper list-inline text-center">
                    <!-- Single Partner -->
                    <li class="single-partner list-inline-item p-lg-3">
                        <img src="assets/img/brand_partner_1.png" alt="">
                    </li>
                    <!-- Single Partner -->
                    <li class="single-partner list-inline-item p-lg-3">
                        <img src="assets/img/brand_partner_2.png" alt="">
                    </li>
                    <!-- Single Partner -->
                    <li class="single-partner list-inline-item p-lg-3">
                        <img src="assets/img/brand_partner_3.png" alt="">
                    </li>
                    <!-- Single Partner -->
                    <li class="single-partner list-inline-item p-lg-3">
                        <img src="assets/img/brand_partner_4.png" alt="">
                    </li>
                    <!-- Single Partner -->
                    <li class="single-partner list-inline-item p-lg-3">
                        <img src="assets/img/brand_partner_5.png" alt="">
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>