<section class="section team-area ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Team -->
                <div class="single-team">
                    <!-- Team Photo -->
                    <div class="team-photo">
                        <a href="#"><img src="assets/img/team_thumb_1.jpg" alt=""></a>
                    </div>
                    <!-- Team Content -->
                    <div class="team-content p-3">
                        <a href="#">
                            <h3 class="mb-2">Junaid Hasan</h3>
                        </a>
                        <h5 class="text-uppercase">Marketing Officer</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Team -->
                <div class="single-team">
                    <!-- Team Photo -->
                    <div class="team-photo">
                        <a href="#"><img src="assets/img/team_thumb_2.jpg" alt=""></a>
                    </div>
                    <!-- Team Content -->
                    <div class="team-content p-3">
                        <a href="#">
                            <h3 class="mb-2">Yasmin Akter</h3>
                        </a>
                        <h5 class="text-uppercase">SEO Expert</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Team -->
                <div class="single-team">
                    <!-- Team Photo -->
                    <div class="team-photo">
                        <a href="#"><img src="assets/img/team_thumb_3.jpg" alt=""></a>
                    </div>
                    <!-- Team Content -->
                    <div class="team-content p-3">
                        <a href="#">
                            <h3 class="mb-2">Md. Arham</h3>
                        </a>
                        <h5 class="text-uppercase">Media Controller</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Team -->
                <div class="single-team">
                    <!-- Team Photo -->
                    <div class="team-photo">
                        <a href="#"><img src="assets/img/team_thumb_4.jpg" alt=""></a>
                    </div>
                    <!-- Team Content -->
                    <div class="team-content p-3">
                        <a href="#">
                            <h3 class="mb-2">Jassica William</h3>
                        </a>
                        <h5 class="text-uppercase">Data Analyst</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Team -->
                <div class="single-team">
                    <!-- Team Photo -->
                    <div class="team-photo">
                        <a href="#"><img src="assets/img/team_thumb_5.jpg" alt=""></a>
                    </div>
                    <!-- Team Content -->
                    <div class="team-content p-3">
                        <a href="#">
                            <h3 class="mb-2">John Doe</h3>
                        </a>
                        <h5 class="text-uppercase">Web Developer</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Team -->
                <div class="single-team">
                    <!-- Team Photo -->
                    <div class="team-photo">
                        <a href="#"><img src="assets/img/team_thumb_6.jpg" alt=""></a>
                    </div>
                    <!-- Team Content -->
                    <div class="team-content p-3">
                        <a href="#">
                            <h3 class="mb-2">Sara Scotch</h3>
                        </a>
                        <h5 class="text-uppercase">Graphic Designer</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>