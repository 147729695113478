<div class="homepage-3">
    <div class="main overflow-hidden">
        <app-scrollup></app-scrollup>
        <app-header-three></app-header-three>
        <app-hero-three></app-hero-three>
        <app-promo-two></app-promo-two>
        <app-content-two></app-content-two>
        <app-content-four></app-content-four>
        <app-service-three></app-service-three>
        <app-pricing-two></app-pricing-two>
        <app-review-two></app-review-two>
        <app-contact-one></app-contact-one>
        <app-cta></app-cta>
        <app-footer-one></app-footer-one>
        <app-modal-search></app-modal-search>
        <app-modal-menu></app-modal-menu>
    </div>
</div>