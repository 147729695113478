import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-portfolio-grid',
  templateUrl: './breadcrumb-portfolio-grid.component.html',
  styleUrls: ['./breadcrumb-portfolio-grid.component.css']
})
export class BreadcrumbPortfolioGridComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
