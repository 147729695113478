<div id="search" class="modal fade p-0">
    <div class="modal-dialog dialog-animated">
        <div class="modal-content h-100">
            <div class="modal-header" data-dismiss="modal">
                CONSULTA LA COMPATIBILIDAD DE TU EQUIPO <i class="far fa-times-circle icon-close"></i>
            </div>
            <div class="modal-body">
                <form class="row" [formGroup]="imeiForm">
                    <div class="col-12 align-self-center">
                        <div class="row">
                            <div class="col-12 pb-3">
                                <h2 class="search-title mb-3">INGRESA EL IMEI DE TU TELÉFONO</h2>
                                <h3>Para consultar el IMEI marca al número *#06# en tu teléfono.</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 input-group">
                                <label for="imei"> </label>
                                <input type="text" class="form-control"  id="imei" name="imei" formControlName="imei" placeholder="IMEI">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="" *ngIf="this.alert===2" class="alert alert-warning" role="alert">
                                    NO ES COMPATIBLE :(
                                </div>
                                <div class="" *ngIf="this.alert===1" class="alert alert-success" role="alert">
                                    ¡ES COMPATIBLE!
                                </div>
                                <div class="" *ngIf="this.alert===3" class="alert alert-info" role="alert">
                                        Espere...
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 input-group align-self-center">
                                <button type="button" (click)="checkCompatibility()" class="btn btn-bordered mt-3">CONSULTAR</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>