import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-pricing',
  templateUrl: './breadcrumb-pricing.component.html',
  styleUrls: ['./breadcrumb-pricing.component.css']
})
export class BreadcrumbPricingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
