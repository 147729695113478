import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-two',
  templateUrl: './review-two.component.html',
  styleUrls: ['./review-two.component.css']
})
export class ReviewTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
