<header id="header">
    <!-- Navbar -->
    <nav data-aos="zoom-out" data-aos-delay="800" class="navbar navbar-expand navbar-light">
        <div class="container header">
            <!-- Navbar Brand-->
            <a class="navbar-brand" href="/">
                <img src="assets/img/logo.png" alt="sticky brand-logo">
            </a>
            <div class="ml-auto"></div>
            <!-- Navbar -->
            <ul class="navbar-nav items">
                <li class="nav-item">
                    <a class="nav-link scroll" href="#home">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link scroll" href="#about">About</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link scroll" href="#services">Services</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link scroll" href="#portfolio">Portfolio</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link scroll" href="#pricing">Pricing</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link scroll" href="#contact">Contact</a>
                </li>
            </ul>
            <!-- Navbar Icons -->
            <ul class="navbar-nav icons">
                <li class="nav-item">
                    <a href="#" class="nav-link" data-toggle="modal" data-target="#search">
                        <i class="fas fa-search"></i>
                    </a>
                </li>
                <li class="nav-item social">
                    <a href="#" class="nav-link"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li class="nav-item social">
                    <a href="#" class="nav-link"><i class="fab fa-twitter"></i></a>
                </li>
            </ul>

            <!-- Navbar Toggler -->
            <ul class="navbar-nav toggle">
                <li class="nav-item">
                    <a href="#" class="nav-link" data-toggle="modal" data-target="#menu">
                        <i class="fas fa-bars toggle-icon m-0"></i>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</header>