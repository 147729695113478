<div class="homepage-6 rtl">
    <div class="main overflow-hidden">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <app-hero-three></app-hero-three>
        <app-pricing-one></app-pricing-one>
        <!-- <app-service-five></app-service-five>
        <app-content-one></app-content-one>
        <app-content-four></app-content-four>
        <app-service-one></app-service-one>
        <app-review-one></app-review-one>
        <app-contact-one></app-contact-one>
        <app-cta></app-cta> -->
        <app-footer-two></app-footer-two>
        <app-modal-search></app-modal-search>
        <app-modal-menu></app-modal-menu>
    </div>
</div>