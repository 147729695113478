import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-theme-eleven',
  templateUrl: './theme-eleven.component.html',
  styleUrls: ['./theme-eleven.component.css']
})
export class ThemeElevenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
