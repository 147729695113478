<section id="pricing" class="section price-plan-area overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Our Price Plans</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-8">
                <div class="row price-plan-wrapper">
                    <div class="col-12 col-md-6">
                        <!-- Single Price Plan -->
                        <div class="single-price-plan color-1 bg-hover hover-top text-center p-5">
                            <!-- Plan Title -->
                            <div class="plan-title mb-2 mb-sm-3">
                                <h3 class="mb-2">Basic</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, nemo.</p>
                            </div>
                            <!-- Plan Price -->
                            <div class="plan-price pb-2 pb-sm-3">
                                <span class="color-primary fw-7">$</span>
                                <span class="h1 fw-7">49</span>
                                <sub class="validity text-muted fw-5">/mo</sub>
                            </div>
                            <!-- Plan Description -->
                            <div class="plan-description">
                                <ul class="plan-features">
                                    <li class="py-2">5GB Linux Web Space</li>
                                    <li class="py-2">5 MySQL Databases</li>
                                    <li class="py-2 text-muted">24/7 Tech Support</li>
                                    <li class="py-2 text-muted">Daily Backups</li>
                                </ul>
                            </div>
                            <!-- Plan Button -->
                            <div class="plan-button">
                                <a href="#" class="btn btn-bordered mt-3">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mt-4 mt-md-0">
                        <!-- Single Price Plan -->
                        <div class="single-price-plan color-2 bg-hover active hover-top text-center p-5">
                            <!-- Plan Title -->
                            <div class="plan-title mb-2 mb-sm-3">
                                <h3 class="mb-2">Pro <sup><span class="badge badge-pill badge-warning ml-2">Recommended</span></sup></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, nemo.</p>
                            </div>
                            <!-- Plan Price -->
                            <div class="plan-price pb-2 pb-sm-3">
                                <span class="color-primary fw-7">$</span>
                                <span class="h1 fw-7">129</span>
                                <sub class="validity text-muted fw-5">/mo</sub>
                            </div>
                            <!-- Plan Description -->
                            <div class="plan-description">
                                <ul class="plan-features">
                                    <li class="py-2">10GB Linux Web Space</li>
                                    <li class="py-2">50 MySQL Databases</li>
                                    <li class="py-2">Unlimited Email</li>
                                    <li class="py-2">Daily Backups</li>
                                </ul>
                            </div>
                            <!-- Plan Button -->
                            <div class="plan-button">
                                <a href="#" class="btn btn-bordered active mt-3">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center pt-5">
            <p class="pt-4 fw-5">Not sure what to choose? <a class="service-btn" href="#"><strong>Contact Us</strong></a></p>
        </div>
    </div>
</section>