<section class="section promo-area ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 res-margin">
                <!-- Single Promo -->
                <div class="single-promo color-1 bg-hover hover-bottom text-center p-5">
                    <h3 class="mb-3">trend design</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. <strong>Aspernatur provident unde</strong> ex eligendi magni sit impedit iusto, sed ad fuga minima, dignissimos ducimus autem molestias, nostrum nesciunt enim? Ea, non hic voluptates dolorum impedit eveniet dolorem temporibus illo incidunt quis minima facere doloribus sit maiores, blanditiis labore quasi, accusantium quaerat!</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 res-margin">
                <!-- Single Promo -->
                <div class="single-promo color-2 bg-hover active hover-bottom text-center p-5">
                    <h3 class="mb-3">Keyword ranking</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur provident unde ex eligendi magni sit impedit iusto, sed ad fuga minima, <strong>dignissimos ducimus autem</strong> molestias, nostrum nesciunt enim? Ea, non hic voluptates dolorum impedit eveniet dolorem temporibus illo incidunt quis minima facere doloribus sit maiores, blanditiis labore quasi, accusantium quaerat!</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Promo -->
                <div class="single-promo color-3 bg-hover hover-bottom text-center p-5">
                    <h3 class="mb-3">Social media</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur provident unde ex eligendi magni sit impedit iusto, sed ad fuga minima, dignissimos ducimus autem molestias, nostrum nesciunt enim? Ea, non hic voluptates <strong>dolorum impedit eveniet dolorem temporibus</strong> illo incidunt quis minima facere doloribus sit maiores, blanditiis labore quasi, accusantium quaerat!</p>
                </div>
            </div>
        </div>
    </div>
</section>