<section id="blog" class="section blog-area ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-3">
                <aside class="sidebar mb-5 mb-lg-0">
                    <!-- Single Widget -->
                    <div class="single-widget">
                        <!-- Search Widget -->
                        <div class="widget-content search-widget">
                            <form action="#">
                                <input type="text" placeholder="Enter your keywords">
                            </form>
                        </div>
                    </div>
                    <!-- Single Widget -->
                    <div class="single-widget">
                        <!-- Category Widget -->
                        <div class="widget catagory-widget">
                            <h5 class="text-uppercase d-block py-3">Categories</h5>
                            <!-- Category Widget Content -->
                            <div class="widget-content">
                                <!-- Category Widget Items -->
                                <ul class="widget-items">
                                    <li><a href="#" class="d-flex py-3"><span>Web Design</span><span class="ml-auto">(14)</span></a></li>
                                    <li><a href="#" class="d-flex py-3"><span>Digital Agency</span><span class="ml-auto">(32)</span></a></li>
                                    <li><a href="#" class="d-flex py-3"><span>Wordpress</span><span class="ml-auto">(27)</span></a></li>
                                    <li><a href="#" class="d-flex py-3"><span>UI Design</span><span class="ml-auto">(18)</span></a></li>
                                    <li><a href="#" class="d-flex py-3"><span>Marketing</span><span class="ml-auto">(15)</span></a></li>
                                    <li><a href="#" class="d-flex py-3"><span>Web Template</span><span class="ml-auto">(29)</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- Single Widget -->
                    <div class="single-widget">
                        <!-- Post Widget -->
                        <div class="widget post-widget">
                            <h5 class="text-uppercase d-block py-3">Popular Post</h5>
                            <!-- Post Widget Content -->
                            <div class="widget-content">
                                <!-- Post Widget Items -->
                                <ul class="widget-items">
                                    <li>
                                        <a href="#" class="single-post media py-3">
                                            <!-- Post Thumb -->
                                            <div class="post-thumb avatar-lg h-100 d-flex align-self-center">
                                                <img src="assets/img/case_studies_1.jpg" alt="">
                                            </div>
                                            <div class="post-content media-body ml-3 py-2">
                                                <p class="post-date mb-2">05 Feb, 2020</p>
                                                <h6>Post Tile Here</h6>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="single-post media py-3">
                                            <!-- Post Thumb -->
                                            <div class="post-thumb avatar-lg h-100 d-flex align-self-center">
                                                <img src="assets/img/case_studies_2.jpg" alt="">
                                            </div>
                                            <div class="post-content media-body ml-3 py-2">
                                                <p class="post-date mb-2">09 Apr, 2020</p>
                                                <h6>Post Tile Here</h6>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="single-post media py-3">
                                            <!-- Post Thumb -->
                                            <div class="post-thumb avatar-lg h-100 d-flex align-self-center">
                                                <img src="assets/img/case_studies_3.jpg" alt="">
                                            </div>
                                            <div class="post-content media-body ml-3 py-2">
                                                <p class="post-date mb-2">13 Jul, 2020</p>
                                                <h6>Post Tile Here</h6>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="single-post media py-3">
                                            <!-- Post Thumb -->
                                            <div class="post-thumb avatar-lg h-100 d-flex align-self-center">
                                                <img src="assets/img/case_studies_4.jpg" alt="">
                                            </div>
                                            <div class="post-content media-body ml-3 py-2">
                                                <p class="post-date mb-2">03 Oct, 2020</p>
                                                <h6>Post Tile Here</h6>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- Single Widget -->
                    <div class="single-widget">
                        <!-- Tags Widget -->
                        <div class="widget tags-widget">
                            <h5 class="text-uppercase d-block py-3">Popular Tags</h5>
                            <!-- Tags Widget Content -->
                            <div class="widget-content">
                                <!-- Tags Widget Items -->
                                <div class="widget-content tags-widget-items pt-2">
                                    <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Digimax</a>
                                    <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Bootstrap</a>
                                    <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Agency</a>
                                    <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Marketing</a>
                                    <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Landing Page</a>
                                    <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Business</a>
                                    <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Fashion</a>
                                    <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Media</a>
                                    <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Lifestyle</a>
                                    <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Wordpress</a>
                                    <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Web Design</a>
                                    <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Mobile App</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
            <div class="col-12 col-lg-9">
                <!-- Single Blog Details -->
                <article class="single-blog-details">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/blog_1.jpg" alt=""></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content digimax-blog p-3">
                        <!-- Meta Info -->
                        <div class="meta-info d-flex flex-wrap align-items-center py-2">
                            <ul>
                                <li class="d-inline-block p-2">By <a href="#">Junaid Hasan</a></li>
                                <li class="d-none d-md-inline-block p-2"><a href="#">05 Feb, 2020</a></li>
                                <li class="d-inline-block p-2"><a href="#">2 Comments</a></li>
                            </ul>
                            <!-- Blog Share -->
                            <div class="blog-share ml-auto d-none d-sm-block">
                                <!-- Social Icons -->
                                <div class="social-icons d-flex justify-content-center">
                                    <a class="bg-white facebook" href="#">
                                        <i class="fab fa-facebook-f"></i>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a class="bg-white twitter" href="#">
                                        <i class="fab fa-twitter"></i>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a class="bg-white google-plus" href="#">
                                        <i class="fab fa-google-plus-g"></i>
                                        <i class="fab fa-google-plus-g"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- Blog Details -->
                        <div class="blog-details">
                            <h3 class="blog-title py-3"><a href="#">Blog Content Here</a></h3>
                            <p class="d-none d-sm-block">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique placeat deleniti repellat alias maiores corrupti, commodi rem eum molestiae distinctio vitae perferendis at esse ex quas hic quis debitis quasi aspernatur incidunt, excepturi sapiente ducimus nisi. Ad molestiae, blanditiis ducimus.</p>
                            <p class="d-block d-sm-none">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est mollitia, tenetur libero voluptate quisquam consectetur facilis natus molestiae officiis aperiam.</p>
                            <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                                <p class="font-italic d-none d-sm-block">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime magnam, molestias omnis quam veritatis consectetur nihil aliquid quo culpa? Ullam molestiae id temporibus nemo inventore, at reiciendis distinctio porro atque!</p>
                                <p class="font-italic d-block d-sm-none">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur ullam dolor, omnis maiores molestiae ducimus.</p>
                            </blockquote>
                            <p class="d-none d-sm-block">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique placeat deleniti repellat alias maiores corrupti, commodi rem eum molestiae distinctio vitae perferendis at esse ex quas hic quis debitis quasi aspernatur incidunt, excepturi sapiente ducimus nisi. Ad molestiae, blanditiis ducimus.</p>
                            <p class="d-block d-sm-none">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est mollitia, tenetur libero voluptate quisquam consectetur facilis natus molestiae officiis aperiam.</p>
                        </div>
                    </div>
                    <!-- Blog Comments -->
                    <div class="blog-comments p-3">
                        <!-- Admin -->
                        <div class="admin media py-4">
                            <!-- Admin Thumb -->
                            <div class="admin-thumb avatar-lg">
                                <img class="rounded-circle" src="assets/img/avatar_1.png" alt="">
                            </div>
                            <!-- Admin Content -->
                            <div class="admin-content media-body pl-3 pl-sm-4">
                                <h4 class="admin-name mb-2"><a href="#">Junaid Hasan</a></h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quo eius nam facere enim, voluptas incidunt officiis molestiae, nostrum dolor? Fugit.</p>
                            </div>
                        </div>
                        <!-- Comments -->
                        <div class="comments mt-4 mb-3">
                            <!-- Comments Title -->
                            <h3 class="comments-title text-uppercase text-left text-lg-right mb-3">Recent Comments</h3>
                            <!-- Single Comments -->
                            <div class="single-comments media p-4">
                                <!-- Comments Thumb -->
                                <div class="comments-thumb avatar-lg">
                                    <img class="rounded-circle" src="assets/img/avatar_2.png" alt="">
                                </div>
                                <!-- Comments Content -->
                                <div class="comments-content media-body pl-3">
                                    <h5 class="d-flex mb-2">
                                        <a href="#">Jassica William</a>
                                        <a href="#" class="ml-auto">Reply</a>
                                    </h5>
                                    <p class="d-none d-lg-block">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere optio ipsum maiores mollitia quisquam.</p>
                                    <p class="d-block d-lg-none">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                                </div>
                            </div>
                            <!-- Single Comments -->
                            <div class="single-comments media p-4">
                                <!-- Comments Thumb -->
                                <div class="comments-thumb avatar-lg">
                                    <img class="rounded-circle" src="assets/img/avatar_3.png" alt="">
                                </div>
                                <!-- Comments Content -->
                                <div class="comments-content media-body pl-3">
                                    <h5 class="d-flex mb-2">
                                        <a href="#">Md. Arham</a>
                                        <a href="#" class="ml-auto">Reply</a>
                                    </h5>
                                    <p class="d-none d-lg-block">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere optio ipsum maiores mollitia quisquam.</p>
                                    <p class="d-block d-lg-none">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Blog Contact -->
                    <div class="blog-contact p-3">
                        <!-- Contact Title -->
                        <h3 class="comments-title text-uppercase text-left text-lg-right mb-3">Post your Comments</h3>
                        <!-- Comment Box -->
                        <div class="contact-box comment-box">
                            <form method="POST" action="#">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" name="name" placeholder="Name" required="required">
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control" name="email" placeholder="Email" required="required">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <textarea class="form-control" name="message" placeholder="Message" required="required"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button class="btn btn-bordered mt-3" type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</section>