<section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7">
                <div class="welcome-intro">
                    <h1 class="text-white"><span class="fw-4">Get your business on</span> Google Search</h1>
                    <p class="text-white-50 my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.</p>
                    <!-- Buttons -->
                    <div class="button-group">
                        <a href="#" class="btn btn-bordered-white active">Start a Project</a>
                        <a href="#" class="btn btn-bordered-white d-none d-sm-inline-block">Contact Us</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb-wrapper mt-5 mt-md-0">
                    <span class="welcome-thumb-1">
                        <img class="d-block ml-auto" src="assets/img/welcome_thumb_11.png" alt="">
                    </span>
                    <span class="welcome-thumb-2">
                        <img class="welcome-animation d-block" src="assets/img/welcome_thumb_12.png" alt="">
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>