<section id="blog" class="section blog-area ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Single Blog -->
                <div class="single-blog res-margin">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/blog_1.jpg" alt=""></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">Anna Sword</a></li>
                            <li><a href="#">Feb 05, 2020</a></li>
                        </ul>
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                        <a href="#" class="blog-btn mt-3">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <!-- Single Blog -->
                <div class="single-blog res-margin">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/blog_2.jpg" alt=""></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">Jassica William</a></li>
                            <li><a href="#">Feb 05, 2020</a></li>
                        </ul>
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                        <a href="#" class="blog-btn mt-3">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <!-- Single Blog -->
                <div class="single-blog">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/blog_3.jpg" alt=""></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">John Doe</a></li>
                            <li><a href="#">Mar 05, 2020</a></li>
                        </ul>
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                        <a href="#" class="blog-btn mt-3">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <!-- Single Blog -->
                <div class="single-blog res-margin">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/blog_4.jpg" alt=""></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">Anna Sword</a></li>
                            <li><a href="#">Feb 05, 2020</a></li>
                        </ul>
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                        <a href="#" class="blog-btn mt-3">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <!-- Single Blog -->
                <div class="single-blog res-margin">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/blog_5.jpg" alt=""></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">Jassica William</a></li>
                            <li><a href="#">Feb 05, 2020</a></li>
                        </ul>
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                        <a href="#" class="blog-btn mt-3">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <!-- Single Blog -->
                <div class="single-blog">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/blog_6.jpg" alt=""></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content">
                        <!-- Meta Info -->
                        <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">John Doe</a></li>
                            <li><a href="#">Mar 05, 2020</a></li>
                        </ul>
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                        <a href="#" class="blog-btn mt-3">Read More</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- Pagination -->
                <ul class="pagination justify-content-center">
                    <li class="disabled px-1">
                        <a href="#" aria-label="Previous">
                            <i class="fas fa-arrow-left"></i>
                        </a>
                    </li>
                    <li class="px-1"><a href="#">1</a></li>
                    <li class="active px-1"><a href="#">2</a></li>
                    <li class="px-1"><a href="#">3</a></li>
                    <li>
                        <a href="#" aria-label="Next">
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>