import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-blog-three-column',
  templateUrl: './breadcrumb-blog-three-column.component.html',
  styleUrls: ['./breadcrumb-blog-three-column.component.css']
})
export class BreadcrumbBlogThreeColumnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
