<section id="service" class="section service-area dark-bg ptb_150">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-white">We provide the best digital services</h2>
                    <p class="text-white-50 d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="text-white-50 d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Service -->
                <div class="single-service media p-3">
                    <span class="flaticon-rocket-launch color-1 icon-bg-1"></span>
                    <!-- Service Text -->
                    <div class="media-body ml-4">
                        <h3 class="text-white">Data Analytics</h3>
                        <p class="text-white-50 mt-2 mb-3">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt emit.</p>
                        <a class="service-btn" href="#">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Service -->
                <div class="single-service media p-3">
                    <span class="flaticon-monitoring color-2 icon-bg-2"></span>
                    <!-- Service Text -->
                    <div class="media-body ml-4">
                        <h3 class="text-white">Website Growth</h3>
                        <p class="text-white-50 mt-2 mb-3">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt emit.</p>
                        <a class="service-btn" href="#">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Service -->
                <div class="single-service media p-3">
                    <span class="flaticon-web color-3 icon-bg-3"></span>
                    <!-- Service Text -->
                    <div class="media-body ml-4">
                        <h3 class="text-white">Seo Ranking</h3>
                        <p class="text-white-50 mt-2 mb-3">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt emit.</p>
                        <a class="service-btn" href="#">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Service -->
                <div class="single-service media p-3">
                    <span class="flaticon-smartphone color-4 icon-bg-4"></span>
                    <!-- Service Text -->
                    <div class="media-body ml-4">
                        <h3 class="text-white">App Development</h3>
                        <p class="text-white-50 mt-2 mb-3">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt emit.</p>
                        <a class="service-btn" href="#">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Service -->
                <div class="single-service media p-3">
                    <span class="flaticon-email color-5 icon-bg-5"></span>
                    <!-- Service Text -->
                    <div class="media-body ml-4">
                        <h3 class="text-white">Email Marketing</h3>
                        <p class="text-white-50 mt-2 mb-3">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt emit.</p>
                        <a class="service-btn" href="#">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Service -->
                <div class="single-service media p-3">
                    <span class="flaticon-affiliate color-6 icon-bg-6"></span>
                    <!-- Service Text -->
                    <div class="media-body ml-4">
                        <h3 class="text-white">Affiliate Marketing</h3>
                        <p class="text-white-50 mt-2 mb-3">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt emit.</p>
                        <a class="service-btn" href="#">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>